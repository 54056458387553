import _isUnitlessValue2 from "./isUnitlessValue";
import _utils2 from "./utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertUnitValue = convertUnitValue;
exports.default = void 0;
var _isUnitlessValue = _isUnitlessValue2;
var _utils = _utils2;
/**
 * Converts a non-unitless CSSProperty value to px.
 * @param {string} prop The CSS prop.
 * @param {string|number} value The value to convert.
 * @returns {string|number} The converted value, if applicable.
 */

function convertUnitValue(prop, value) {
  if (!(0, _utils.isNumber)(value)) {
    return value;
  }

  return (0, _isUnitlessValue.isUnitlessValue)(prop) ? value : value + "px";
}

var _default = convertUnitValue;
exports.default = _default;
export default exports;