var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPlainObject = isPlainObject;
exports.isDefined = isDefined;
exports.isString = isString;
exports.isNumber = isNumber;

function isPlainObject(value) {
  return isDefined(value) && value.toString() === "[object Object]";
}

function isDefined(value) {
  return typeof value !== "undefined" && value !== null;
}

function isString(value) {
  return typeof value === "string";
}

function isNumber(value) {
  return typeof value === "number";
}

export default exports;