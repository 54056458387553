import _memoizeOne2 from "memoize-one";
import _tinyWarning2 from "tiny-warning";
import _isStylePropValid2 from "./isStylePropValid";
import _convertUnitValue2 from "./convertUnitValue";
import _utils2 from "./utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rawSanitizeStyleProps = rawSanitizeStyleProps;
exports.sanitizeStyleProps = void 0;

var _memoizeOne = _interopRequireDefault(_memoizeOne2);

var _tinyWarning = _interopRequireDefault(_tinyWarning2);

var _isStylePropValid = _isStylePropValid2;
var _convertUnitValue = _convertUnitValue2;
var _utils = _utils2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * Removes non style CSSProperty props from an object. Converts
 * non-unitless values to px values.
 * @param {object} props The collection of props to sanitize.
 * @returns {object} A collection of style CSSProperty safe key/value pairs.
 */


function rawSanitizeStyleProps(props) {
  if (!(0, _utils.isPlainObject)(props)) {
    (0, _tinyWarning.default)(false, "prop needs to be a plain object.");
    return {};
  }

  var keys = Object.keys(props);
  return keys.reduce(function (nextProps, prop) {
    var value = props[prop];
    var isValueValid = (0, _utils.isString)(value) || (0, _utils.isNumber)(value);

    if (isValueValid && (0, _isStylePropValid.isStylePropValid)(prop)) {
      var _objectSpread2;

      var nextValue = (0, _convertUnitValue.convertUnitValue)(prop, value);
      return _objectSpread({}, nextProps, (_objectSpread2 = {}, _objectSpread2[prop] = nextValue, _objectSpread2));
    }

    return nextProps;
  }, {});
}
/**
 * Removes non style CSSProperty props from an object. Converts
 * non-unitless values to px values.
 * @param {object} props The collection of props to sanitize.
 * @returns {object} A collection of style CSSProperty safe key/value pairs.
 */


var sanitizeStyleProps = (0, _memoizeOne.default)(rawSanitizeStyleProps);
exports.sanitizeStyleProps = sanitizeStyleProps;
export default exports;