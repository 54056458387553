import _CSSProperty2 from "./CSSProperty";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUnitlessValue = isUnitlessValue;
exports.default = void 0;
var _CSSProperty = _CSSProperty2;
/**
 * Determines if the CSSProperty is a unitless value. For example, `lineHeight`.
 * @param {string} prop The prop to check.
 * @returns {boolean} The result.
 */

function isUnitlessValue(prop) {
  return _CSSProperty.unitlessStyleProps.includes(prop);
}

var _default = isUnitlessValue;
exports.default = _default;
export default exports;